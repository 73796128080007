import React, { useEffect, useState } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import CloseIcon from '@/assets/svg/close_noti_icon.svg';
import SuccessIcon from '@/assets/image/toast_success_icon.png';
import ErrorIcon from '@/assets/image/toast_error_icon.png';
import styles from './index.module.less';
import { DOM_ID } from '@const/index';
import cn from 'classnames';

type ToastType = 'success' | 'error';
interface ToastProps {
  msg?: string;
  duration?: number;
  afterClose?: () => void;
  rootDom?: HTMLElement | null;
  theme?: 'white' | 'black';
  type?: ToastType;
}

const IconMap: Record<ToastType, string> = {
  success: SuccessIcon,
  error: ErrorIcon,
};

const Toast: React.FC<ToastProps> = ({
  msg,
  afterClose,
  duration,
  theme = 'white',
  type,
}) => {
  const [show, setShow] = useState(false);

  const close = () => {
    setShow(false);
    if (typeof afterClose === 'function') {
      // 需要等动画结束后再移除，增加1s延时
      setTimeout(afterClose, 1000);
    }
  };

  useEffect(() => {
    setShow(true);
    const timer = setTimeout(close, duration);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  if (theme === 'white') {
    return (
      <div className={styles.wrap}>
        <div className={cn(styles.container, show && styles['container_show'])}>
          <div className="font-lg text-black">{msg}</div>
          <CloseIcon className={styles.closeIcon} onClick={close} />
        </div>
      </div>
    );
  }
  return (
    <div className={styles.blackWrap}>
      <div
        className={cn(styles.blackContainer, show && styles['container_show'])}
      >
        {type && <img src={IconMap[type]} className={styles.icon}></img>}
        <div className={styles.text}>{msg}</div>
      </div>
    </div>
  );
};

Toast.defaultProps = { duration: 3000 };

function createToast(props: ToastProps) {
  if (document.getElementById('toast')) {
    // 已经有toast，不再重复显示
    return;
  }
  const div = document.createElement('div');
  div.id = DOM_ID.toastId;
  let parent: Element | null | undefined = props.rootDom;
  if (!parent) {
    parent = document.body;
  }
  parent.appendChild(div);

  const destroy = () => {
    const unmountResult = unmountComponentAtNode(div);
    if (unmountResult && div.parentNode) {
      div.parentNode.removeChild(div);
    }
  };

  render(<Toast {...props} afterClose={destroy} />, div);
}

export default {
  show: (msg: string, config: ToastProps = {}) => {
    createToast({
      msg,
      ...config,
    });
  },
};
