/*
 * @Author: lijianling
 * @Date: 2022-08-09 16:04:44
 * @LastEditors: xiaoyu.hao xiaoyu.hao@shopee.com
 * @LastEditTime: 2023-09-11 12:01:24
 * @Description: services of help-center
 */
import request from '@/utils/request';
import { defaultL, fetchPageJson } from '@/utils/resource';
import {
  HOT_QUESTIONS_CATEGORY_ID,
  DOMAIN_MARK,
  LINK_MARK,
  ROOT_PATH,
} from '@/constant/helpCenter';
import { reduceToObject } from '@/utils/misc';
import { getCdnDomain } from '@/utils/url';

const transformContent = (content?: string) => {
  if (!content) return '';
  const reg = new RegExp(
    `(http://${DOMAIN_MARK})|(https://${DOMAIN_MARK})`,
    'g',
  );
  const _content = content.replace(reg, `//${getCdnDomain?.()}`);
  const linkReg = new RegExp(LINK_MARK, 'g');
  return _content.replace(linkReg, `${ROOT_PATH}/article`);
};

const transformArticle = (data: question): question => {
  const title = data.title;

  return {
    ...data,
    title,
    content: transformContent(data.content),
    relatedArticles: data?.relatedArticles || [],
  };
};

export const getHotQuestions = async () => {
  return await request<HotQuestionItem[]>(
    `/hsapi/hot/v1/list?language=${defaultL}`,
    {
      method: 'POST',
    },
  );
};

export const getSearchResult = async (keyword: string) => {
  const rsp = await request<SearchResultItem[]>(
    `/hsapi/article/v1/search?language=${defaultL}`,
    {
      method: 'POST',
      body: {
        keyword,
      },
    },
  );
  return (rsp || []).map((v: SearchResultItem) => ({
    ...v,
    highlight: v.title,
  }));
};

export const getDetail = async (id: number) => {
  const rsp = await request<question>(
    `/hsapi/article/v1/detail?language=${defaultL}`,
    {
      method: 'POST',
      body: {
        articleId: id,
      },
    },
  );
  return rsp ? transformArticle(rsp) : ((null as unknown) as question);
};

export const rateArticle = async (body: RateArticleReq) => {
  return await request<boolean>('/hsapi/article/v1/rate', {
    method: 'POST',
    body,
  });
};

export const getReasonList = async () => {
  return await request<GetReasonListRsp>('/hsapi/reason/v1/list', {
    method: 'POST',
  });
};

export const getDetails = async (ids: number[]) => {
  const rsp = await Promise.all(ids.map((id) => getDetail(id)));
  return rsp.map((item) => {
    if (item) return transformArticle(item);
    return item;
  });
};

export const getCategories = async () => {
  const template = await fetchPageJson('faq');
  const content = reduceToObject(template.content, 'render');
  const list = await request<Category[]>(
    `/hsapi/category/v1/list?language=${defaultL}`,
    {
      method: 'POST',
    },
  );

  return [
    {
      categoryId: HOT_QUESTIONS_CATEGORY_ID,
      categoryName: content['Category'].topQuestions,
      leaf: true,
    },
    ...list,
  ] as Category[];
};

export const getArticlesInCategory = async ({
  categoryId,
  pageSize,
  pageNum,
}: {
  categoryId: number;
  pageSize: number;
  pageNum: number;
}) => {
  const rsp = await request<ArticlesInCategoryRsp>(
    `/hsapi/category/v1/article/list?pageSize=${pageSize}&pageNum=${pageNum}`,
    {
      method: 'POST',
      body: {
        categoryId,
      },
    },
  );
  return {
    total: rsp.totalElement,
    data: rsp.data,
  };
};

export const preloadArticles = async ({
  categoryIds,
  pageSize,
}: {
  categoryIds: number[];
  pageSize: number;
}) => {
  if (!Array.isArray(categoryIds)) return;

  const promiseList = categoryIds.map(async (categoryId) => {
    const data = await getArticlesInCategory({
      categoryId,
      pageSize,
      pageNum: 1,
    });

    return {
      categoryId,
      data,
    };
  });

  return (await Promise.all(promiseList)) as PrefetchCategoryArticleRsp[];
};
