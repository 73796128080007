/*
 * @Author: Shuhui Cai
 * @Date: 2022-10-24 17:50:54
 * @LastEditors: Cheng Zhao
 * @LastEditTime: 2023-04-20 17:37:34
 * @Description:
 */
export const DOMAIN_MARK = '{{domain}}';

// according to PM requirement, links should be hard-coded instead of placed in config
const FEEDBACK_LINK_LIVE =
  'https://cs.seabank.ph/portal/webform/0bedff0667d94e518691b61cbc0760a1';

export const FEEDBACK_LINK_LIVE_HELP_CENTER = `${FEEDBACK_LINK_LIVE}?entryPoint=138`;
export const FEEDBACK_LINK_LIVE_FOOTER = `${FEEDBACK_LINK_LIVE}?entryPoint=139`;

const FEEDBACK_LINK_NON_LIVE =
  'https://cs.uat.seabank.ph/portal/webform/c4ad25bd24b647639f806ef819e8278c';

export const FEEDBACK_LINK_NON_LIVE_HELP_CENTER = `${FEEDBACK_LINK_NON_LIVE}?entryPoint=138`;
export const FEEDBACK_LINK_NON_LIVE_FOOTER = `${FEEDBACK_LINK_NON_LIVE}?entryPoint=139`;

export const externalLinkMap: Record<string, string> = {
  FEEDBACK_LINK_LIVE_HELP_CENTER,
  FEEDBACK_LINK_LIVE_FOOTER,
  FEEDBACK_LINK_LIVE,

  FEEDBACK_LINK_NON_LIVE_HELP_CENTER,
  FEEDBACK_LINK_NON_LIVE_FOOTER,
  FEEDBACK_LINK_NON_LIVE,
};
