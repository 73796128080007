export interface BrowserObject {
  isIos: boolean;
  isAndroid: boolean;
  isMobile: boolean;
  isHuawei: boolean;
  isPc: boolean;
}

export function getBrowserObject(ua: string): BrowserObject {
  const browser = {} as BrowserObject;
  const isIos = /(iphone|ipad|ipod|ios)/i.test(ua);
  let isAndroid: boolean = false;
  let isHuawei: boolean = false;
  if (/(huawei|honor)/i.test(ua)) {
    isHuawei = true;
  } else if (/(android|linux)/i.test(ua)) {
    isAndroid = true;
  }
  browser.isIos = isIos;
  browser.isAndroid = isAndroid;
  browser.isHuawei = isHuawei;
  browser.isMobile = isIos || isAndroid || isHuawei;
  browser.isPc = !isIos && !isAndroid && !isHuawei;

  return browser;
}

export function detectBrowser(userAgent?: string): BrowserObject {
  if (typeof window !== 'undefined') {
    const ua = window.navigator.userAgent.toLowerCase();
    return getBrowserObject(ua);
  }
  if (userAgent) {
    return getBrowserObject(userAgent);
  }
  return {
    isIos: false,
    isAndroid: false,
    isHuawei: false,
    isMobile: false,
    isPc: true,
  };
}

export const isSafariOnMobile = () => {
  const ua = window.navigator.userAgent;
  const isIOS = /iPhone|iPad|iPod/i.test(ua);
  return isIOS && /Safari/i.test(ua) && !/CriOS/i.test(ua);
};
