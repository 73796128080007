import { isType } from '@/utils/misc';
import { throwError } from '@/utils/error';
import { ApiHost } from '@/services/base';
const JSONContentTypeHeader = { 'Content-Type': 'application/json' };

export const toJSON = (data: object) => JSON.stringify(data);

type IBody = RequestInit['body'] | object;
interface IRequestOption extends Omit<RequestInit, 'body'> {
  query?: Record<string, string | number>;
  body?: IBody;
  loadingText?: string;
  overrideCDN?: boolean;
}

interface IGetConfigProps {
  method?: string;
  headers: object;
  body: IBody;
  noCredentials?: boolean;
}
const getConfig = ({ method, headers, body }: IGetConfigProps) => {
  const defaultHeaders = {
    'shopee-banking-platform': '2', // 1-App 2-Web
  };
  const config: RequestInit = {
    method: method || 'GET',
  };
  if (config.method === 'GET') {
    Object.assign(defaultHeaders, JSONContentTypeHeader); // GET请求header全部要加上'Content-Type': 'application/json'
  }
  if (method === 'POST') {
    if (isType(body, 'Object') || isType(body, 'Array')) {
      Object.assign(defaultHeaders, JSONContentTypeHeader);
      config.body = toJSON(body as object);
    } else {
      config.body = body as RequestInit['body'];
    }
  }
  const resolvedHeaders = {
    ...defaultHeaders,
    ...headers,
  };

  config.headers = resolvedHeaders;
  return config;
};

const request = async <T = any>(api: string, options: IRequestOption = {}) => {
  const {
    query,
    body = {},
    headers = {},
    method,
    // GET requests should not request cdn-origin by default
    overrideCDN = method === 'POST',
    ...rest
  } = options;

  const config = getConfig({ method, body, headers });

  const _api =
    overrideCDN && !/^https?:/.test(api)
      ? `${ApiHost}${api.startsWith('/') ? api : '/' + api}`
      : api;

  return fetch(_api, {
    ...config,
    ...rest,
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      throwError(res.statusText, res.status);
    })
    .then((res) => {
      if (res.code) {
        throwError(res.data || res.msg || res.message, res.code);
      }
      return res.data as T;
    })
    .catch((err) => {
      throwError(err);
      return (null as unknown) as T;
    });
};

export default request;
