/*
 * @Author: xiaoyu.hao xiaoyu.hao@shopee.com
 * @Date: 2023-09-11 18:00:20
 * @LastEditors: xiaoyu.hao xiaoyu.hao@shopee.com
 * @LastEditTime: 2023-09-12 14:08:08
 * @FilePath: /website/src/utils/storageUtil.ts
 * @Description:
 *
 */
type TDate = 'h' | 'w' | 'd' | 'm' | 's';

interface IStorage {
  value: any;
  key: string;
  expire?: string;
}
export const storageUtil = {
  set(storeData: IStorage) {
    const date = { expire: 0 };
    storeData?.expire && this.processExpire(storeData.expire, date);
    window.localStorage.setItem(
      storeData.key,
      JSON.stringify({ expire: date.expire, value: storeData.value }),
    );
  },
  get(key: string) {
    let res = window.localStorage.getItem(key) as any;
    try {
      res && (res = JSON.parse(res));
    } catch (e) {
      //不处理
    }
    if (res?.expire && res.expire < Date.now()) {
      window.localStorage.removeItem(key);
      res = null;
    }
    return res;
  },
  delete(key: string) {
    window.localStorage.removeItem(key);
  },
  processExpire(expire: string, date: { expire: number }) {
    const time = expire.match(/\d/)![0],
      timerUnit: TDate = expire.split(time)[1].toLocaleLowerCase() as TDate;
    switch (timerUnit) {
      case 's':
        date.expire = Number(time) * 1000;
        break;
      case 'm':
        date.expire = Number(time) * 1000 * 60;
        break;
      case 'h':
        date.expire = Number(time) * 1000 * 60 * 60;
        break;
      case 'd':
        date.expire = Number(time) * 1000 * 60 * 60 * 24;
        break;
      case 'w':
        date.expire = Number(time) * 1000 * 60 * 60 * 24 * 7;
        break;
    }
    date.expire += Date.now();
  },
};
